import React from 'react';
import useContactForm from '../utils/contact-form';

const ContactForm = () => {
  const {
    handleSubmit,
    errors,
    status,
    isSubmitting,
    clearStatus
  } = useContactForm('Solicitud de Cotización');

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target));
    const success = await handleSubmit(formData);
    if (success) {
      e.target.reset();
    }
  };

  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="mb-4">
        <label htmlFor="from_name" className="block mb-2 text-sm font-medium text-gray-700">Nombre</label>
        <input
          type="text"
          id="from_name"
          name="from_name"
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500"
        />
        {errors.name && <p className="mt-1 text-sm text-red-600">{errors.name}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="from_email" className="block mb-2 text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          id="from_email"
          name="from_email"
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500"
        />
        {errors.email && <p className="mt-1 text-sm text-red-600">{errors.email}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-700">Teléfono</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500"
        />
        {errors.phone && <p className="mt-1 text-sm text-red-600">{errors.phone}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-700">Mensaje</label>
        <textarea
          id="message"
          name="message"
          rows="4"
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500"
        ></textarea>
        {errors.message && <p className="mt-1 text-sm text-red-600">{errors.message}</p>}
      </div>

      <button 
        type="submit" 
        disabled={isSubmitting}
        className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:opacity-50"
      >
        {isSubmitting ? 'Enviando...' : 'Enviar'}
      </button>

      {status.message && (
        <p className={`mt-4 text-center text-${status.type === 'success' ? 'green' : 'red'}-600`}>
          {status.message}
        </p>
      )}
    </form>
  );
};

export default ContactForm;