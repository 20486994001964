import React from 'react';
import Layout from '../Components/Layout';
import useContactForm from '../utils/contact-form';

const Work = () => {
  const {
    handleSubmit,
    errors,
    status,
    isSubmitting
  } = useContactForm('Solicitud de Trabajo');

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target));
    const success = await handleSubmit(formData);
    if (success) {
      e.target.reset();
    }
  };

  return (
    <Layout>
      <section className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-6 text-blue-600 text-center">
            Trabajá con nosotros
          </h2>

          <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-8 mt-8">
            <p className="text-gray-600 mb-8 text-center">
              En Barracas Trucks buscamos personas comprometidas y apasionadas.
              Completá el formulario y unite a nuestro equipo.
            </p>

            <form onSubmit={onSubmit} className="space-y-6">
              <div>
                <label htmlFor="from_name" className="block text-sm font-medium text-gray-700">
                  Nombre completo *
                </label>
                <input
                  type="text"
                  name="from_name"
                  id="from_name"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                )}
              </div>

              <div>
                <label htmlFor="from_email" className="block text-sm font-medium text-gray-700">
                  Email *
                </label>
                <input
                  type="email"
                  name="from_email"
                  id="from_email"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                )}
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  Teléfono *
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-600">{errors.phone}</p>
                )}
              </div>

              <div>
                <label htmlFor="position" className="block text-sm font-medium text-gray-700">
                  Puesto al que aspira *
                </label>
                <select
                  name="position"
                  id="position"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Chofer">Chofer</option>
                  <option value="Mecánico">Mecánico</option>
                  <option value="Administrativo">Administrativo</option>
                  <option value="Logística">Logística</option>
                  <option value="Otro">Otro</option>
                </select>
              </div>

              <div>
                <label htmlFor="cv_link" className="block text-sm font-medium text-gray-700">
                  Link a tu CV *
                </label>
                <input
                  type="url"
                  name="cv_link"
                  id="cv_link"
                  required
                  placeholder="https://drive.google.com/..."
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                <p className="mt-1 text-sm text-gray-500">
                  Por favor, sube tu CV a <a href="https://drive.google.com/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">Google Drive</a>, <a href="https://www.dropbox.com/es/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">Dropbox</a> o similar y comparte el link aquí, si no, adjunta el CV en formato PDF directamente en un mail a <a href="mailto:administracion@barracastrucks.com.ar" className="text-blue-600 hover:text-blue-800">administracion@barracastrucks.com.ar</a>
                </p>
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  Mensaje adicional
                </label>
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  placeholder="Experiencia laboral, disponibilidad horaria, nivel de estudios, licencia de conducir (en caso de aplicar para chofer), conocimientos específicos, referencias laborales, etc."
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                ></textarea>
                {errors.message && (
                  <p className="mt-1 text-sm text-red-600">{errors.message}</p>
                )}
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isSubmitting ? 'Enviando...' : 'Enviar solicitud'}
              </button>

              {status.message && (
                <p className={`text-center text-${status.type === 'success' ? 'green' : 'red'}-600`}>
                  {status.message}
                </p>
              )}
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Work;