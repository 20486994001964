import React, { useEffect, useRef } from 'react';
import { scrollToElement } from '../utils/navigation';

const Intro = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    
    // Función para manejar errores de carga del video
    const handleVideoError = () => {
      console.error('Error loading video');
      if (video) {
        video.style.display = 'none';
      }
    };

    // Función para reiniciar el video si se detecta que está pausado
    const checkVideoPlayback = () => {
      if (video && video.paused && !video.ended) {
        video.play().catch(error => {
          console.error('Error playing video:', error);
        });
      }
    };

    if (video) {
      // Configurar opciones de video para mejor rendimiento
      video.playsInline = true;
      video.muted = true;
      video.defaultMuted = true;
      video.autoplay = true;
      video.load();

      // Intentar reproducir el video inmediatamente
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error('Error autoplaying video:', error);
        });
      }

      // Agregar event listeners
      video.addEventListener('error', handleVideoError);
      
      // Verificar periódicamente si el video está reproduciéndose
      const playbackCheck = setInterval(checkVideoPlayback, 5000);

      // Limpiar event listeners y interval
      return () => {
        video.removeEventListener('error', handleVideoError);
        clearInterval(playbackCheck);
      };
    }
  }, []);

  return (
    <section id="intro" className="bg-dark-blue-800 text-gray-100 relative h-screen flex items-center justify-center overflow-hidden">
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        playsInline
        muted
        loop
        preload="auto"
        poster="/images/video-poster.jpg" 
      >
        <source src="/videos/corporate-trucks.mp4" type="video/mp4" />
        <source src="/videos/corporate-trucks.webm" type="video/webm" /> {/* Versión WebM para mejor compatibilidad */}
        Your browser does not support the video tag.
      </video>

      {/* Overlay azul con transparencia */}
      <div className="absolute top-0 left-0 w-full h-full bg-dark-blue-800/60" />

      <div className="relative z-10 text-center px-4">
        <h1 className="text-4xl md:text-6xl font-bold mb-8 text-white">
          Excelencia en el transporte
        </h1>
        <div className="flex justify-center space-x-4">
          <a
            href="#contact"
            onClick={scrollToElement('contact')}
            className="px-6 py-3 bg-white text-dark-blue-800 rounded-lg font-semibold transform transition-transform duration-150 active:translate-y-1 hover:shadow-lg"
          >
            Pedí tu cotización! ✉
          </a>
        </div>
      </div>
    </section>
  );
};

export default Intro;