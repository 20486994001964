import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Mission = () => {
  const [activeTab, setActiveTab] = useState('politica');

  const tabs = [
    { id: 'politica', label: 'Política de Calidad' },
    { id: 'mision', label: 'Misión y Visión' },
    { id: 'valores', label: 'Valores' }
  ];

  const content = {
    politica: {
      title: "Nuestra Política de Calidad",
      content: [
        "En Barracas Trucks, nos comprometemos a brindar servicios de transporte y logística de la más alta calidad, cumpliendo con los estándares más exigentes de la industria.",
        "Nos esforzamos por mantener una mejora continua en todos nuestros procesos, implementando las últimas tecnologías y mejores prácticas del sector.",
        "Contamos con certificación ISO 9001:2015 que avala nuestro compromiso con la calidad y la mejora continua.",
      ]
    },
    mision: {
      title: "Misión y Visión",
      content: [
        "Misión: Ser líderes en soluciones logísticas integrales, brindando un servicio de excelencia que supere las expectativas de nuestros clientes.",
        "Visión: Consolidarnos como la empresa de transporte y logística más confiable y eficiente de Argentina, siendo referentes en innovación y calidad de servicio."
      ]
    },
    valores: {
      title: "Nuestros Valores",
      content: [
        "Compromiso con la excelencia",
        "Integridad y transparencia",
        "Trabajo en equipo",
        "Innovación constante",
        "Responsabilidad social y ambiental",
        "Orientación al cliente"
      ]
    }
  };

  return (
    <section className="bg-white py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-blue-600 hover:text-blue-800 text-center">
          Nuestra Empresa
        </h2>

        <div className="max-w-4xl mx-auto">
          {/* Tabs */}
          <div className="flex justify-center mb-8 space-x-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-6 py-3 rounded-lg font-medium transition-all duration-300 ${
                  activeTab === tab.id
                    ? 'bg-blue-600 text-white shadow-lg'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {/* Content */}
          <div className="relative min-h-[300px]">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                className="bg-white p-8 rounded-lg shadow-md"
              >
                <h3 className="text-2xl font-semibold mb-6 text-gray-800">
                  {content[activeTab].title}
                </h3>
                <div className="space-y-4">
                  {content[activeTab].content.map((item, index) => (
                    <p key={index} className="text-gray-600 leading-relaxed">
                      {activeTab === 'valores' ? `• ${item}` : item}
                    </p>
                  ))}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;