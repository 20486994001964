import React from 'react';
import { motion } from 'framer-motion';
import { FaLinkedin, FaInstagram, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import ContactForm from './ContactForm';

const Contact = ({ theme }) => {
  const iconVariants = {
    tap: { scale: 0.95 }
  };

  const contactItems = [
    { icon: FaEnvelope, text: 'trafico@barracastrucks.com.ar', href: 'mailto:trafico@barracastrucks.com.ar' },
    { icon: FaWhatsapp, text: '+54 11 3933-3372', href: 'https://wa.me/5491139333372' },
    { icon: FaWhatsapp, text: '+54 11 3154-4828', href: 'https://wa.me/5491131544828' },
    { icon: FaLinkedin, text: 'LinkedIn', href: 'https://www.linkedin.com/company/barracas-trucks-srl' },
    { icon: FaInstagram, text: 'Instagram', href: 'https://www.instagram.com/barracas_trucks/' },
  ];

  return (
    <motion.section
      id="contact"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="py-10"
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/3 mb-8 md:mb-0">
            <motion.h2
              className="text-3xl font-bold mb-6 text-blue-600"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              Contacto
            </motion.h2>
            <motion.div
              className="space-y-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              {contactItems.map((item, index) => (
                <motion.a
                  key={index}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center space-x-3 text-gray-700"
                  variants={iconVariants}
                  whileTap="tap"
                >
                  <item.icon size={20} className="flex-shrink-0" />
                  <span className="text-sm">{item.text}</span>
                </motion.a>
              ))}
            </motion.div>
          </div>
          <div className="md:w-2/3">
            <ContactForm />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Contact;