import React from 'react';
import { BiLogoKickstarter } from "react-icons/bi";
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="bg-dark-blue-900 text-gray-400 py-12">
    <div className="container mx-auto px-4">
      <div className="grid md:grid-cols-3 gap-8">
        <div>
          <h3 className="text-xl font-semibold mb-4">Dirección Buenos Aires</h3>
          <p>
            Cno. Gral. Manuel Belgrano 1756<br />
            Quilmes Oeste<br />
            Buenos Aires<br />
            Argentina
          </p>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-4">Dirección Santa Fe</h3>
          <p>
            Cnel. Juan B. Thorne<br />
            Timbúes<br />
            Santa Fe<br />
            Argentina
          </p>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-4">Contacto</h3>
          <p>
            <a href="mailto:trafico@barracastrucks.com.ar" className="hover:text-gray-200">trafico@barracastrucks.com.ar</a>
          </p>
          <p>
            11 3933-3372<br />
            11 3154-4828
          </p>
        </div>
      </div>
      <div className="mt-8 text-center">
        <Link
          to="/"
          className="hover:text-gray-200 transition duration-300"
        >
          <p>Barracas Trucks®</p>
        </Link>
      </div>
    </div>
    <div className="container mx-auto px-4 flex justify-center items-center">
      <a href="https://kevinbnanezdev.web.app/" target="_blank" rel="noopener noreferrer" className="flex items-center hover:text-gray-300 transition duration-300">
        <BiLogoKickstarter className="mr-2" />
        <span className="text-sm">Diseño web</span>
      </a>
    </div>
  </footer>
);

export default Footer;