import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BarracasPage from './Pages/BarracasPage';
import Work from './Pages/Work';
import Claims from './Pages/Claims';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BarracasPage />} />
        <Route path="/careers" element={<Work />} />
        <Route path="/claims" element={<Claims />} />
      </Routes>
    </Router>
  );
}

export default App;