import React from 'react';

const Maps = () => {
  const locations = [
    {
      title: "Sede Buenos Aires",
      address: "Cno. Gral. Manuel Belgrano 1756, Quilmes Oeste",
      embedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1159.002021215269!2d-58.30500254822943!3d-34.75174468184096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a333375366a6bd%3A0x514d63a39f43873f!2sBarracas%20Trucks!5e0!3m2!1ses!2sar!4v1679121566121!5m2!1ses!2sar"
    },
    {
      title: "Sede Santa Fe",
      address: "Av. Estanislao López, S2204 Timbúes, Santa Fe",
      embedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3383.5908785273745!2d-60.78121219933624!3d-32.67026702172235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f11.5!3m3!1m2!1s0x95b6435fe971a12f%3A0x3678c4c08f54db67!2sBarracas%20Trucks%20SRL!5e0!3m2!1ses!2sar!4v1679122288206!5m2!1ses!2sar"
    }
  ];

  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-blue-600 hover:text-blue-800 text-center">
          Nuestras Ubicaciones
        </h2>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {locations.map((location, index) => (
            <div key={index} className="flex flex-col">
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                {location.title}
              </h3>
              <p className="text-gray-600 mb-4">
                {location.address}
              </p>
              <div className="relative w-full h-0 pb-[40%] rounded-lg overflow-hidden shadow-lg">
                <iframe
                  src={location.embedUrl}
                  className="absolute top-0 left-0 w-full h-full border-0"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title={`Mapa de ${location.title}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Maps;