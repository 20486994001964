import React from 'react';
import Layout from '../Components/Layout';
import Intro from '../Components/Intro';
import Services from '../Components/Services';
import Reviews from '../Components/Reviews';
import Clients from '../Components/Clients';
import Contact from '../Components/Contact';
import Features from '../Components/Features';
import Mission from '../Components/Mission';
import Maps from '../Components/Maps';

const BarracasPage = () => {
  return (
    <Layout>
      <Intro />
      <Services />
      <Features />
      <Mission />
      <Reviews />
      <Contact />
      <Maps />
    </Layout>
  );
};

export default BarracasPage;