import React, { useState, useRef, useEffect } from 'react';
import { Star, Edit3 } from 'lucide-react';

const StarRating = () => (
  <div className="flex justify-center mt-4">
    {[...Array(5)].map((_, i) => (
      <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    ))}
  </div>
);

const Review = ({ text, author, link }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-6 h-full text-center">
    <a href={link} target="_blank" rel="noopener noreferrer" className="block h-full">
      <p className="text-gray-600 mb-4 italic">{text}</p>
      <cite className="text-gray-800 font-semibold block mb-2">{author}</cite>
      <StarRating />
    </a>
  </div>
);

const Reviews = () => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);
  const animationRef = useRef(null);

  const animate = () => {
    if (containerRef.current && !isMouseDown) {
      containerRef.current.scrollLeft += 1;

      if (containerRef.current.scrollLeft >=
        containerRef.current.scrollWidth - containerRef.current.clientWidth) {
        containerRef.current.scrollLeft = 0;
      }
    }
    animationRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    animationRef.current = requestAnimationFrame(animate);
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isMouseDown]);

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX);
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const reviews = [
    {
      text: "Realmente una empresa seria y profesional. Hasta ultima milla y el nivel de servicio fue sobresaliente. Tienen deposito y también brindan almacenamiento y cross dock. Cuentan con tecnología de geolocalización y software de punta para la administración.",
      author: "Pablo Ezequiel Iriarte",
      link: "https://goo.gl/maps/yq9ZLRJ6U5vBcLQ3A"
    },
    {
      text: "Si tenes que elegir una compañía de transporte, esta es la indicada. Tenes contacto directo con el dueño y siempre hay buena predisposición para encontrar soluciones. Saludos",
      author: "Gabriel Kornmacher",
      link: "https://goo.gl/maps/z7VhpsExHGGo5FXt6"
    },
    {
      text: "Excelente atención y nivel de servicio!!! Cumple con todo lo contratado.",
      author: "Omar Wopr",
      link: "https://goo.gl/maps/p3jFKZ16BScAwrMp6"
    },

    {
      text: "La mercadería llegó en perfecto estado Precio razonable y lo que más me sorprendió en tantos años de actividad , que siendo la primera vez que cambiamos de empresa , el cuidado en la carga y descarga y la puntualidad marcaron la diferencia. Gracias !",
      author: "Rosanna Gonzalez",
      link: "https://maps.app.goo.gl/vRAXDTz3iyg5xwKi8"
    },

    {
      text: "Excelente servicio,  muy profesional, siempre están atentos a todos los detalle, cumplen con todo lo acordado.",
      author: "Pablo",
      link: "https://maps.app.goo.gl/1JeXAPqp84Eq7Q1VA"
    },

    {
      text: "Excelente atención. Excelente servicio. Satifacción garantizada.",
      author: "Luis Angel Yamasato",
      link: "https://maps.app.goo.gl/U8L8dZGCNJTFonj28"
    },

    {
      text: "Excelente servicio! Destacado profesionalismo, atención y calidad humana! Muchas gracias Juan! Nos veremos nuevamente!",
      author: "Martin Iguchi",
      link: "https://maps.app.goo.gl/v54W3xdeiUcKMc3y6"
    },

    {
      text: "Empresa de trayectoria, muy profesionales  y sobre todo un grupo  excelente de trabajo.",
      author: "Vanesa Castro",
      link: "https://maps.app.goo.gl/kzfUrBkYqJ8MNkay6"
    },

    {
      text: "Siempre respondiendo a todas las consultas con agilidad, y dando solución a todo. Excelente empresa y servicio.",
      author: "Yamila Mendez",
      link: "https://maps.app.goo.gl/gP4SS5JNDHq6Rubz6"
    },

    {
      text: "Excelente servicio! recomiendo 100%",
      author: "Viviana Vago",
      link: "https://maps.app.goo.gl/c9x8NMTExgfM94NTA"
    },

    {
      text: "¡Super recomendable! Excelente servicio y atención. Juan Manuel muchas gracias por estar al pendiente de cada detalle.",
      author: "Verónica Luna",
      link: "https://maps.app.goo.gl/eSe2XAZqeU1Xvk2D9"
    },

    {
      text: "Excelente calidad de servicio y recursos humanos muy responsables y capacitados. La recomiendo!!!",
      author: "Silvia Corral",
      link: "https://maps.app.goo.gl/B5KAwLzkmfXiQrRW8"
    },

    {
      text: "Muy buen servicio! Desde que empezó la pandemia operamos con ellos y nunca tuvimos problemas. Siempre buena predisposición y profesionalismo.",
      author: "Distribuidora Uniformes Sur",
      link: "https://maps.app.goo.gl/tJxwvTCQqCMAcBD27"
    },

    {
      text: "Llego a ustedes por una recomendación y la verdad que no me puedo quejar de nada, todo 10 puntos, buena atencion, con quien hable un genio, están en todo y los transportes son una maravilla, obviamente los recomendaría y de acá en adelante siempre me manejaré con ustedes. Gracias",
      author: "Leonardo De Bari",
      link: "https://maps.app.goo.gl/5D6J68ggAcCPJKe16"
    },

    {
      text: "Excelente servicio y atención! Hemos realizado transporte de containers en varias oportunidades. Muy recomendable.",
      author: "David Lucero",
      link: "https://maps.app.goo.gl/jvTCYpLR6fAgmgN79"
    },
  ];

  return (
    <section id="opiniones" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-12 text-blue-600 hover:text-blue-800 text-center">
            Opiniones de nuestros clientes
          </h2>
          <a
            href="https://goo.gl/maps/obpprjXqMebk7yu56"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-white text-blue-600 rounded-full shadow-md hover:shadow-lg transition-all duration-300 border border-gray-200"
          >
            <Edit3 className="w-5 h-5" />
            <span className="font-medium">Escribir una reseña</span>
            <Star className="w-5 h-5 text-yellow-400" />
          </a>
        </div>

        <div
          ref={containerRef}
          className="flex overflow-x-hidden gap-8 cursor-grab active:cursor-grabbing select-none"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={(e) => {
            setIsMouseDown(true);
            setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
            setScrollLeft(containerRef.current.scrollLeft);
          }}
          onTouchMove={(e) => {
            if (!isMouseDown) return;
            const x = e.touches[0].pageX - containerRef.current.offsetLeft;
            const walk = (x - startX);
            containerRef.current.scrollLeft = scrollLeft - walk;
          }}
          onTouchEnd={handleMouseUp}
        >
          {[...reviews, ...reviews].map((review, index) => (
            <div key={index} className="min-w-[280px] max-w-[280px] flex-shrink-0">
              <Review {...review} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reviews;