import { useState } from 'react';
import emailjs from '@emailjs/browser';

const useContactForm = (formType = 'Solicitud de Cotización') => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lastSubmission, setLastSubmission] = useState(null);
  const [status, setStatus] = useState({ type: '', message: '' });
  const [errors, setErrors] = useState({});

  const validateForm = (formData) => {
    const errors = {};
    const email = formData.from_email?.trim();
    const name = formData.from_name?.trim();
    const phone = formData.phone?.trim();
    const message = formData.message?.trim();

    // Validaciones específicas por tipo de formulario
    switch (formType) {
      case 'Reclamo':
        const { incident_date, incident_time, location, type } = formData;
        if (!incident_date) errors.incident_date = "La fecha del incidente es requerida";
        if (!incident_time) errors.incident_time = "La hora del incidente es requerida";
        if (!location) errors.location = "La ubicación es requerida";
        if (!type) errors.type = "Debe seleccionar un tipo de reporte";
        if (!message) errors.message = "La descripción es requerida";
        break;

      case 'Solicitud de Trabajo':
        if (!name) errors.name = "El nombre es requerido";
        if (!email) {
          errors.email = "El email es requerido";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          errors.email = "Email inválido";
        }
        if (!phone) {
          errors.phone = "El teléfono es requerido";
        } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(phone)) {
          errors.phone = "Teléfono inválido (debe tener 10 dígitos)";
        }
        if (!formData.position) errors.position = "Debe seleccionar un puesto";
        if (!formData.cv_link) {
          errors.cv_link = "El link del CV es requerido";
        } else if (!/^https?:\/\/.+/.test(formData.cv_link)) {
          errors.cv_link = "Debe ser una URL válida";
        }
        break;

      default: // Solicitud de Cotización y otros casos
        if (!name) errors.name = "El nombre es requerido";
        if (!email) {
          errors.email = "El email es requerido";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          errors.email = "Email inválido";
        }
        if (!phone) {
          errors.phone = "El teléfono es requerido";
        } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(phone)) {
          errors.phone = "Teléfono inválido (debe tener 10 dígitos)";
        }
        if (!message) errors.message = "El mensaje es requerido";
    }

    return errors;
  };

  const handleSubmit = async (formData) => {
    setErrors({});
    setStatus({ type: '', message: '' });

    const now = Date.now();
    if (lastSubmission && now - lastSubmission < 60000) {
      setStatus({
        type: 'error',
        message: 'Por favor, espere un minuto antes de enviar otro mensaje.'
      });
      return false;
    }

    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false;
    }

    setIsSubmitting(true);

    try {
      const isReclamo = formType === 'Reclamo';
      const isTrabajo = formType === 'Solicitud de Trabajo';
      const useReclaimTemplate = isReclamo || isTrabajo;

      let templateParams;

      if (useReclaimTemplate) {
        // Template para reclamos y trabajo
        templateParams = {
          to_name: 'Administración',
          to_email: 'administracion@barracastrucks.com.ar',
          reply_to: formData.from_email,
          from_name: formData.from_name || 'Anónimo',
          from_email: formData.from_email || 'no-email@proporcionado.com',
          phone: formData.phone || 'No proporcionado',
          type: isTrabajo ? 'Solicitud de Trabajo' : formData.type,
          incident_date: isTrabajo ? new Date().toLocaleDateString() : formData.incident_date,
          incident_time: isTrabajo ? new Date().toLocaleTimeString() : formData.incident_time,
          location: isTrabajo ? 'N/A' : formData.location,
          unit_number: isTrabajo ? 'N/A' : (formData.unit_number || 'No proporcionado'),
          plate_number: isTrabajo ? 'N/A' : (formData.plate_number || 'No proporcionado'),
          message: isTrabajo ?
            `Puesto solicitado: ${formData.position}\nCV Link: ${formData.cv_link}\n\nMensaje adicional: ${formData.message || 'No proporcionado'}` :
            formData.message,
          submit_date: new Date().toLocaleString('es-AR')
        };
      } else {
        // Template para contacto/cotización
        templateParams = {
          to_name: 'Administración',
          to_email: 'administracion@barracastrucks.com.ar',
          reply_to: formData.from_email,
          from_name: formData.from_name || 'Anónimo',
          from_email: formData.from_email || 'no-email@proporcionado.com',
          phone: formData.phone || 'No proporcionado',
          form_type: formType,
          message: formData.message,
          submit_date: new Date().toLocaleString('es-AR')
        };
      }

      await emailjs.send(
        'service_ae39akq',
        useReclaimTemplate ? 'template_muh9lm7' : 'template_06v17fh',
        templateParams,
        'qSbjhV5V3r9CnyaBq'
      );

      setStatus({
        type: 'success',
        message: 'Mensaje enviado con éxito'
      });
      setLastSubmission(now);
      return true;

    } catch (error) {
      console.error('Error:', error);
      setStatus({
        type: 'error',
        message: 'Error al enviar el mensaje. Por favor, intente de nuevo.'
      });
      return false;

    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit,
    errors,
    status,
    isSubmitting,
    clearStatus: () => setStatus({ type: '', message: '' }),
    clearErrors: () => setErrors({})
  };
};

export default useContactForm;