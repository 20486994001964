import React from 'react';
import { 
  Truck, 
  Package, 
  Boxes, 
  AlertTriangle, 
  Globe, 
  CheckCircle, 
  Award, 
  Shield, 
  Radio, 
  Building2
} from 'lucide-react';

const Features = () => {
  const features = [
    {
      icon: Truck,
      title: "Transporte de cargas generales",
    },
    {
      icon: Boxes,
      title: "Servicios de almacenamiento",
    },
    {
      icon: Package,
      title: "Servicios de logística",
    },
    {
      icon: AlertTriangle,
      title: "Transporte de mercancias peligrosas",
    },
    {
      icon: Globe,
      title: "Transporte internacional",
    },
    {
      icon: CheckCircle,
      title: "Habilitaciones SENASA",
    },
    {
      icon: Award,
      title: "Empresa habilitada bajo normas ISO",
    },
    {
      icon: Shield,
      title: "Habilitaciones SEDRONAR",
    },
    {
      icon: Radio,
      title: "Rastreo Satelital",
    },
    {
      icon: Building2,
      title: "Depósitos en Buenos Aires y Santa Fe",
    }
  ];

  return (
    <section className="bg-gray-50 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6 text-blue-600 hover:text-blue-800 text-center">
          Qué hacemos
        </h2>
        <p className="text-gray-600 text-lg mb-12 max-w-3xl mx-auto text-center">
          En Barracas Trucks, nos especializamos en ofrecer servicios de transporte y logística personalizados 
          para satisfacer las necesidades de cada cliente. Nuestro enfoque se centra en la seguridad, la 
          eficiencia y la puntualidad, para garantizar la satisfacción del cliente.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <div className="mb-4 p-3 bg-blue-100 rounded-full">
                <feature.icon 
                  className="w-8 h-8 text-blue-600"
                  strokeWidth={1.5}
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-800">
                {feature.title}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;